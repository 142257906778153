import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { getTanqueCambioByFilters } from '../../helpers/cambio/apiCalls'
import { TableBase } from './TableBase'
import { FormBase } from './FormBase'
import Grid from '@material-ui/core/Grid';
import { isEmpty } from 'lodash'
import Spinner from '../base/Spinner'
import Typography from '@material-ui/core/Typography'
import { ExcelExportBase } from '../base/ExcelExportBase'
import { TablaDeStock } from '../base/TablaDeStock'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  }
}));

export const AgendarCambioScreen = () => {
  const classes = useStyles();
  const [data, setData] = useState({})
  const [reload, setReload] = useState('')
  const [openSpinner, setOpenSpinner] = useState(false)

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      setOpenSpinner(true)
      const zona = isEmpty(JSON.parse(sessionStorage.getItem('zonaAgendarCambio'))) ? null : JSON.parse(sessionStorage.getItem('zonaAgendarCambio')).id_zona
      const canal = isEmpty(JSON.parse(sessionStorage.getItem('canalCambio'))) ? null : JSON.parse(sessionStorage.getItem('canalCambio'))
      const orgVentas = isEmpty(JSON.parse(sessionStorage.getItem('orgVentasCambio'))) ? null : JSON.parse(sessionStorage.getItem('orgVentasCambio'))
      const mesDesde = isEmpty(JSON.parse(sessionStorage.getItem('mesDesdeCambio'))) ? null : JSON.parse(sessionStorage.getItem('mesDesdeCambio'))
      const mesHasta = isEmpty(JSON.parse(sessionStorage.getItem('mesHastaCambio'))) ? null : JSON.parse(sessionStorage.getItem('mesHastaCambio'))
      const frecuencia = isEmpty(JSON.parse(sessionStorage.getItem('frecuenciaCambio'))) ? null : JSON.parse(sessionStorage.getItem('frecuenciaCambio'))
      const tipo = isEmpty(JSON.parse(sessionStorage.getItem('tipoTanqueCambio'))) ? null : JSON.parse(sessionStorage.getItem('tipoTanqueCambio'))
      const checklock = isEmpty(JSON.parse(sessionStorage.getItem('checklock'))) ? null : JSON.parse(sessionStorage.getItem('checklock'))
      const comuna = isEmpty(JSON.parse(sessionStorage.getItem('comuna'))) ? null : JSON.parse(sessionStorage.getItem('comuna'))
      const porcentaje = isEmpty(sessionStorage.getItem('porcentaje')) ? null : sessionStorage.getItem('porcentaje')
      const resultadoVisita = isEmpty(JSON.parse(sessionStorage.getItem('resultadoVisita'))) ? null : JSON.parse(sessionStorage.getItem('resultadoVisita'))

      try {
        const result = await getTanqueCambioByFilters(zona, canal, orgVentas, mesDesde, mesHasta, frecuencia, tipo, checklock, comuna, porcentaje, resultadoVisita)
        if (isMounted) {
          setData(result)
          setOpenSpinner(false)
        }
      } catch (error) {
        if (isMounted) {
          setOpenSpinner(false)
        }
        console.error("Error fetching data", error)
      }
    }

    fetchData()

    return () => {
      isMounted = false;
    }

  }, [reload])

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" style={{ fontFamily: '"Poppins", sans-serif' }} >
            Agendar Cambio
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormBase
            setReload={setReload}
            reload={reload}
          />
        </Grid>
        {data && data.columns &&
          <Grid container item>
            <Grid item style={{ backgroundColor: 'white' }}>
              <ExcelExportBase
                header={data.columns}
                data={data.rows}
                title={'_cambioTanque_'}
              />
            </Grid>
            <Grid item style={{ width: '60%' }}></Grid>
            <Grid item style={{ backgroundColor: 'white' }}>
              <TablaDeStock programados={'Falta dato'} centros={'Falta dato'} />
            </Grid>
          </Grid>
        }
        {data && data.columns &&
          <Grid item xs={12}>
            <TableBase
              getData={data}
              data={data.rows}
            />
          </Grid>
        }
      </Grid>

      <Spinner open={openSpinner} />
    </div>
  )
}