import { isEmpty } from 'lodash'
const apiEstanquesCambioUrl = process.env.REACT_APP_API_ESTANQUES_CAMBIO_URL
const myHeaders = new Headers()
myHeaders.append('Content-Type', 'application/json')


const token = localStorage.getItem('token');

export const getTanqueCambioByFilters = async (zona, canal, orgVentas, mesDesde, mesHasta, frecuencia, tipo_estanque, checklock, comuna, porcentaje, resultadoVisita) => {
	const url = `${apiEstanquesCambioUrl}/cambio`

	const raw = {
		"zona": isEmpty(zona) ? null : zona,
		"canal": isEmpty(canal) ? null : canal,
		"orgVentas": isEmpty(orgVentas) ? null : orgVentas,
		"mesDesde": isEmpty(mesDesde) ? null : mesDesde,
		"mesHasta": isEmpty(mesHasta) ? null : mesHasta,
		"frecuencia": isEmpty(frecuencia) ? null : frecuencia,
		"tipo_estanque": isEmpty(tipo_estanque) ? null : tipo_estanque,
		"checklock": isEmpty(checklock) ? null : checklock,
		"comuna": isEmpty(comuna) ? null : comuna,
		"porcentaje": isEmpty(porcentaje) ? null : porcentaje,
		"resultadoVisita": isEmpty(resultadoVisita) ? null : resultadoVisita,
	};

	try {
		const resp = await fetch(url, {
			method: 'post',
			headers: myHeaders,
			body: JSON.stringify(raw),
			redirect: 'follow'
		})

		if (resp.status === 200 || resp.status === 201) {
			const data = await resp.json()

			return {
				rows: data.data,
				columns: columnsEstanques,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener los pedidos`
		}
	}
}

const columnsEstanques = [
	//{ field: 'distancia', headerName: 'Distancia', width: 140, align: 'right' },
	{ field: 'dm', headerName: 'DM', width: 200 },
	{ field: 'fecha_revision', headerName: 'Fecha Revisión', width: 200, editable: true },
	{ field: 'motivo_gestion', headerName: 'Motivo Gestión', width: 200, editable: true },
	{ field: 'fecha_quiebre', headerName: 'Fecha de quiebre', width: 200 },
	{ field: 'mes_vencimiento', headerName: 'Mes Vencimiento', width: 200 },
	{ field: 'razon_social', headerName: 'Nombre Cliente', width: 300 },

	{ field: 'telefono', headerName: 'Teléfono', width: 140 },
	{ field: 'telefono2', headerName: 'Teléfono 2', width: 150 },
	{ field: 'emailc2', headerName: 'Email', width: 350 },

	{ field: 'comuna', headerName: 'Comuna', width: 200 },
	{ field: 'orgvta_vkorg', headerName: 'Org. Ventas', width: 160 },
	{ field: 'resultado_visita', headerName: 'Resultado visita', width: 500 },
	{ field: 'porcentaje_gas', headerName: '% Gas', width: 200 },
	{ field: 'check_lock', headerName: 'Check lock', width: 200 },
	{ field: 'tipo_camion', headerName: 'Tipo Camión', width: 200 }, //desde resultados

	{ field: 'tipo_tanque', headerName: 'Tipo Tanque', width: 200 },
	{ field: 'codigo_inventario', headerName: 'Material', width: 200 },
	{ field: 'inyectos_3_meses', headerName: 'Inyectos 3 meses', width: 200 },
	{ field: 'inyectos_6_meses', headerName: 'Inyectos 6 meses', width: 200 },
	{ field: 'inyectos_12_meses', headerName: 'Inyectos 12 meses', width: 210 },
	{ field: 'fecha_ultimo_inyecto', headerName: 'Fecha último inyecto', width: 230 },

	{ field: 'obs1', headerName: 'Observación 1', width: 300 },
	{ field: 'obs2', headerName: 'Observación 2', width: 300 },

	{ field: 'oc_em', headerName: 'OC/EM', width: 200 },
	{ field: 'telemetria', headerName: 'TELEMETRIA', width: 200 },
	{ field: 'n_ticket_asoc', headerName: 'N° Ticket Asociado', width: 220 },

	{ field: 'id_externo', headerName: 'id_externo', width: 200 },
]

export const addAgenda = async (values) => {
	//myHeaders.set('Authorization', `Bearer ${ token }`)
	const url = `${apiEstanquesCambioUrl}/agenda/addAgenda`

	try {
		const resp = await fetch(url, {
			method: 'POST',
			headers: myHeaders,
			body: JSON.stringify(values),
			redirect: 'follow'
		})

		console.log(resp.status)

		if (resp.status === 200 || resp.status === 201 || resp.status === 204) {
			const respuesta = await resp.json()

			if (respuesta.cod_error === 9000) {
				return {
					message: 'Guardado exitosamente',
					status: 'OK'
				}
			} else {
				return {
					message: 'No se pudo guardar',
					status: 'NOK'
				}
			}

		} else {
			return {
				status: 'NOK',
				message: "Ocurrió un error al procesar la solicitud"
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para guardar`
		}
	}
}

export const getAcciones = async (tipo) => {
	const url = `${apiEstanquesCambioUrl}/accion`

	const raw = {
		"tipo": tipo
	};

	try {
		const resp = await fetch(url, {
			method: 'post',
			headers: myHeaders,
			body: JSON.stringify(raw),
			redirect: 'follow'
		})

		if (resp.status === 200 || resp.status === 201) {
			const data = await resp.json()

			return {
				acciones: data.data,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener las acciones`
		}
	}
}

export const getBloquesHorarios = async (id_zona, fecha) => {
	const url = `${apiEstanquesCambioUrl}/agenda/bloquesHorarios`

	const body = {
		id_zona: id_zona,
		fecha: fecha
	}

	try {
		const resp = await fetch(url, {
			method: 'POST',
			headers: myHeaders,
			body: JSON.stringify(body),
			redirect: 'follow'
		})

		if (resp.status === 200 || resp.status === 201) {
			const data = await resp.json()

			return {
				bloques: data.data,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener los bloques horarios`
		}
	}
}

export const getClienteDistancias = async (latitud, longitud) => {
	//const url = `${ apiUrl }/login`
	const url = `${apiEstanquesCambioUrl}/cliente/clienteDistancias`

	const body = {
		latitud: latitud,
		longitud: longitud
	}

	try {
		const resp = await fetch(url, {
			method: 'POST',
			headers: myHeaders,
			body: JSON.stringify(body),
			redirect: 'follow'
		})

		if (resp.status === 200 || resp.status === 201) {
			const { data, respuesta } = await resp.json()

			return {
				data: data,
				status: 'OK'
			}
		} else {
			return {
				status: 'NOK',
				message: "Ocurrió un error al procesar la solicitud"
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener las distancias`
		}
	}
}

export const getHistoria = async (ot) => {
	const url = `${apiEstanquesCambioUrl}/historia`

	const body = {
		ot: ot
	}

	try {
		const resp = await fetch(url, {
			method: 'POST',
			headers: myHeaders,
			body: JSON.stringify(body),
			redirect: 'follow'
		})

		if (resp.status === 200 || resp.status === 201) {
			const data = await resp.json()

			return {
				rows: data.data,
				columns: columnsHistoria,
				status: 'OK',
				message: ''
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener la historia`
		}
	}
}

export const columnsHistoria = [
	{ field: 'id', headerName: 'Historia', width: 130 },
	{ field: 'fecha_hora', headerName: 'Fecha - Hora', width: 220 },
	{ field: 'observacion', headerName: 'Observacion', width: 500 },
	{ field: 'accion', headerName: 'Acción', width: 200 },
	{ field: 'ot', headerName: 'OT', width: 180 }
]

export const getCanales = async (values) => {
	const url = `${apiEstanquesCambioUrl}/cliente/canales`

	try {
		const resp = await fetch(url, {
			method: 'get',
			headers: myHeaders
		})

		if (resp.status === 200 || resp.status === 201) {
			const data = await resp.json()

			return {
				canales: data.data,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener los canales`
		}
	}
}

export const getMonthYear = async (values) => {
	const url = `${apiEstanquesCambioUrl}/cliente/monthYear`

	try {
		const resp = await fetch(url, {
			method: 'get',
			headers: myHeaders
		})

		if (resp.status === 200 || resp.status === 201) {
			const data = await resp.json()

			return {
				monthYear: data.data,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener los meses y años`
		}
	}
}

export const getOrgVentas = async (values) => {
	const url = `${apiEstanquesCambioUrl}/cliente/orgVentas`

	try {
		const resp = await fetch(url, {
			method: 'get',
			headers: myHeaders
		})

		if (resp.status === 200 || resp.status === 201) {
			const data = await resp.json()

			return {
				orgVentas: data.data,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener las OrgVentas`
		}
	}
}

export const getZonas = async (values) => {
	const url = `${apiEstanquesCambioUrl}/zona`

	try {
		const resp = await fetch(url, {
			method: 'get',
			headers: myHeaders
		})

		if (resp.status === 200 || resp.status === 201) {
			const data = await resp.json()

			return {
				zonas: data.data,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener las zonas`
		}
	}
}

export const getResultados = async (id_externo) => {
	const url = `${apiEstanquesCambioUrl}/agenda/resultadoAgenda`

	const body = {
		id_externo: id_externo
	}

	try {
		const resp = await fetch(url, {
			method: 'POST',
			headers: myHeaders,
			body: JSON.stringify(body),
			redirect: 'follow'
		})

		if (resp.status === 200 || resp.status === 201) {
			const data = await resp.json()

			return {
				resultadoAgenda: data.data,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener los resultados`
		}
	}
}

export const generarURLFirmada = async (link) => {
	const url = `${apiEstanquesCambioUrl}/arcgis/generarURLFirmada`;

	const regex = /^https:\/\/storage\.cloud\.google\.com\/([^/]+)\/(.+)$/;
	const match = link.match(regex);

	if (!match || match.length !== 3) {
		return {
			status: 'NOK',
			message: 'El enlace proporcionado no es válido'
		};
	}

	const bucketName = match[1];
	const fileName = match[2];

	const body = {
		bucketName: bucketName,
		fileName: fileName,
		expiration: 600
	};

	try {
		const resp = await fetch(url, {
			method: 'POST',
			headers: myHeaders,
			body: JSON.stringify(body),
			redirect: 'follow'
		});

		if (resp.status === 200) {
			const respuesta = await resp.json();

			return {
				status: 'OK',
				url: respuesta.mensaje_usr
			};
		} else {
			const { errors } = await resp.json();
			return {
				status: 'NOK',
				message: errors[0].msg
			};
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: 'No se pudo conectar para obtener los resultados'
		};
	}
}

export const getAgenda = async (zona, fecha) => {
	const url = `${apiEstanquesCambioUrl}/agenda`

	const body = {
		zona: zona,
		fecha: fecha
	}

	try {
		const resp = await fetch(url, {
			method: 'POST',
			headers: myHeaders,
			body: JSON.stringify(body),
			redirect: 'follow'
		})

		if (resp.status === 200 || resp.status === 201) {
			const data = await resp.json()

			console.log(data)

			return {
				rows: data.data,
				columns: columnsAgenda,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener los pedidos`
		}
	}
}

const columnsAgenda = [
	//{ field: 'hora', headerName: 'Hora', width: 150, headerAlign: 'right', align: 'right' },
	{ field: 'descripcion', headerName: 'Estado', width: 150, headerAlign: 'right', align: 'right' },
	//{ field: 'distancia', headerName: 'Distancia (km)', width: 180, headerAlign: 'right', align: 'right' },
	{ field: 'ot', headerName: 'OT', width: 120, headerAlign: 'right', align: 'right' },
	{ field: 'dm', headerName: 'DM', width: 120, headerAlign: 'right', align: 'right' },
	{ field: 'orgvta_vkorg', headerName: 'Org. Ventas', width: 160, headerAlign: 'right', align: 'right' },
	{ field: 'codigo_tanque', headerName: 'Cod. Tanque', width: 180, headerAlign: 'right', align: 'right' },
	{ field: 'telefono', headerName: 'Teléfono', width: 140, headerAlign: 'right', align: 'right' },
	{ field: 'telefono2', headerName: 'Teléfono 2', width: 150, headerAlign: 'right', align: 'right' },
	{ field: 'razon_social', headerName: 'Nombre Cliente', width: 200, headerAlign: 'right', align: 'right' },
	{ field: 'calle', headerName: 'Calle', width: 300, headerAlign: 'right', align: 'right' },
	{ field: 'numero', headerName: 'Número', width: 140, headerAlign: 'right', align: 'right' },
	{ field: 'otros', headerName: 'Otros', width: 300, headerAlign: 'right', align: 'right' },
	{ field: 'referencia', headerName: 'Referencia', width: 500, headerAlign: 'right', align: 'right' },
	{ field: 'comuna', headerName: 'Comuna', width: 150, headerAlign: 'right', align: 'right' },
	{ field: 'emailc2', headerName: 'Email', width: 350, headerAlign: 'right', align: 'right' },
	{ field: 'id_zona', headerName: 'Zona', width: 120, headerAlign: 'right', align: 'right' },
]